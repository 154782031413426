import { Link } from 'react-router-dom'
import { Logo } from '../Logo'
import './Footer.css'

export const Footer = () => {

    return (
        <div className="footer">
            <div className="footer-box">
                <div className="footer-top">
                    <Logo />
                    <Link className='terms-and-conditions' to = '/terms-and-conditions'>Terms and conditions</Link>
                </div>
                <div className="location-box">
                    <span className='footer-box__location'>Unit No. ALMAS-09-C, Almas Tower</span>
                    <span className='footer-box__location'>Plot No: JLT-PH1-A0 Jumeirah Lakes Towers, Dubai</span>
                    <span className='footer-box__location'>United Arab Emirates</span>
                </div>
                <span className='footer-box__rights'>© 2024 WBS Shipbrokers. All rights reserved.</span>
            </div>
        </div>
    )
}