export const headerMenu = [
    {
        id: 1,
        title: 'About Us',
        link: '/about-us'
    },
    {
        id: 2,
        title: 'Business Lines',
        link: '/business-lines'
    }
]